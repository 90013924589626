import React from 'react'
import SEO from "../../components/theme/seo"
import Layout from "../../components/theme/layout"
import Display from "../../components/display"
import WrapsImg from "../../components/images/wraps-decals/vinyl-wraps"

export default function Wraps() {
    return (
        <>
            <Layout>
                <SEO title="Vinyl Wraps" />
                <Display
                    title="Vinyl Wraps"
                    description="From small home-based businesses to large corporations, mobile advertising is a proven way to get a solid return on investment and increase your visibility."
                    descriptionTwo=" Use full or partial wraps on your boats, busses, trucks, vans, motorcycles, cars, trailers and more – getting top mileage for your advertising dollar."
                    prev="/wraps-decals/decals-signs"
                    next="/wraps-decals/decals-signs"
                    image={<WrapsImg />}
                />
            </Layout>                
        </>
    )
}
